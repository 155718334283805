@import "./styles/variables";

.ClubsMap {
  width: 100%;
  height: calc(100vh - 32rem);
  overflow: hidden;
  margin: 1rem auto 0;
  @include tablet {
    margin: .1rem auto;
    margin-top:0;
    width: $tablet;
    height: calc(100vh - 31rem);
  }
  @include desktop {
    width: $desktop;
  }
  > aside {
    position: absolute;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    display: flex;
    background-color: #ffffff;
    justify-content: center;
    font-family: Montserrat, sans-serif;
    .ClubPreview {
      display: flex;
      width: 100%;
      margin-bottom: -1rem;
      @include desktop {
        margin-bottom: 0;
      }
    }
  }
}