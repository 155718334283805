@import "./styles/variables";

.promo {
  margin: 0.5rem 0.1rem;
  @include tablet {
    margin: .5rem calc((100% - $tablet) / 2 + 1.5rem);
  }
}
.GeoSearch {
  padding: 0 1.5rem;
  @include mobile {
    flex-direction: row;
  }
  @include tablet {
    padding: .5rem calc((100% - $tablet) / 2 + 1.5rem);
  }
  > fieldset  {
    flex-direction: row;
    background-color: #ffffff;
    border: 1px solid #000;
    @include mobile {
      margin-right: 1rem;
      flex-grow: 1;
    }
    &.error {
      border-color: #e63f49;
    }
    input[type="text"] {
        position: relative;
      border: 1px solid #b8b8b8;
      background-color: #ffffff;
      cursor: pointer;
      transition: all .3s ease;
      outline: none;
      width: auto;
      line-height: 1.125em;
      font-style: normal;
      font-size: 1rem;
      text-align: left;
      justify-content: center;
      &::placeholder {
        font-weight: 300;
        font-family: Montserrat, sans-serif;
        text-transform: none;
      }
      border: none;
      padding: .75rem 0 .75rem .75rem;
      flex-grow: 1;
      cursor: text;
    }
  }
  > button {
    padding: 0 1rem;
    font-size: .85em;
    flex-shrink: 0;
    img {
      height: 1.5rem;
      @include tablet {
        margin-left: .5rem;
      }
    }
    span {
      padding: .75rem;
      @include mobile {
        display: none;
      }
      @include tablet {
        display: flex;
      }
    }
  }
}
