@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,500;0,600;0,700;1,400&family=Paytone+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,800;1,300;1,400;1,600;1,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto::ital,wght@0,300;0,400;0,600;0,800;1,300;1,400;1,600;1,800&display=swap');

html {
  font-size: 14px;
  font-weight: 300;
  font-family: 'Kanit', sans-serif;
  background-color: #f7f7f7;
  color: #1d1d1f;
}

@media screen and (min-width: 380px) {
  html {
    font-size: 16px;
  }
}

body > div:not(.pac-container) {
  min-height: 100vh;
}

.pac-item {
  display: inherit !important;
  span {
    display: inline !important;
    span {
      display: inline !important;
      span {
        display: inline !important;
      }
    }
  }
}

main {
  flex-grow: 1;
  padding-top: 5rem;
}

a {
  color: inherit;
  text-decoration: none;
}

.LabelInputText {
  margin-bottom: 1rem;
  
  &:first-of-type {
    align-self: flex-start;
  }
  input[type="text"], input[type="email"], input[type="phone"] {
    position: relative;
    border: 1px solid #b8b8b8;
    background-color: #ffffff;
    cursor: pointer;
    transition: all .3s ease;
    outline: none;
    width: auto;
    line-height: 1.125em;
    font-style: normal;
    font-size: 1rem;
    text-align: left;
    justify-content: center;
    &::placeholder {
      font-weight: 300;
      font-family: Montserrat, sans-serif;
      text-transform: none;
    }
    padding: .75rem .5rem;
    &.error {
      border-color: #e63f49;
    }
  }
}


form.PromoCode {
  padding-left: 0.5rem;
  flex-direction: column;
  > h4 {
    font-weight: 600;
    padding-bottom:0.5rem;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-self: flex-start;
    background-position: center;
  }

  div {
    display:flex;
    flex-direction:row;
  }
}



aside.PromoCode {
  display: flex;
  padding-left: 0.5rem;
  align-items: baseline;
  flex-direction: row;
  justify-content: space-between;
  > h4 {
    font-weight: 600;
  }
}
