@import "../styles/variables";

.Card {
  .StepsDisplay {
    > li {
      .filled {
        pointer-events: none;
      }
    }
  }
  h2 {
    font-weight: 800;
    font-size: 1.25rem;
    text-transform: uppercase;
  }
  > header {
    flex-direction: row;
    padding: 0 1.5rem;
    justify-content: space-between;
    margin-bottom: 1rem;
    @include tablet {
      padding: 0 calc((100% - $tablet) / 2 + 1.5rem);
    }
  }
  > section {
    padding: 1.5rem;
    width: 100%;
    @include tablet {
      margin-bottom: 2rem;
      padding: 2rem calc((100% - $tablet) / 2 + 1.5rem);
    }

    > .CartSummary {
      margin-bottom: 1.5rem;
      @include tablet {
        margin-bottom: 2rem;
      }
    }
  }

  > aside {
    position: sticky;
    background-color: #ffffff;
    bottom: 0;
    padding: 1.5rem;
    margin-top: auto;
    @include tablet {
      padding: 2rem calc((100% - $tablet) / 2 + 1.5rem);
    }
    > em {
      margin-bottom: 1rem;
      font-weight: 600;
    }
    .ButtonLoader {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      transition: all .3s ease;
      align-items: center;
      justify-content: center;
      height: auto;
      font-size: 1rem;
      font-family: Montserrat, sans-serif;
      text-transform: uppercase;
      font-weight: 800;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);


      border: 1px solid #000;
      background-color: #000;
      color: #fff;
      &:hover {
        border-color: lighten(#000, 10);
        background-color: lighten(#000, 10);
        color: #fff;
      }
    }
  }
}