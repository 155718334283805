@import "./styles/variables";

.Header {
  position: fixed;
  background-color: #fff;
  width: 100%;
  z-index: 11000;
  height: 5rem;
  @include tablet {
    padding: 0 calc((100% - $tablet) / 2);
  }
  @include desktop {
    padding: 0 calc((100% - $desktop) / 2);
  }
}