.ButtonLoader {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: all .3s ease;
  align-items: center;
  justify-content: center;
  height: auto;
  font-size: 1rem;
  font-family: Montserrat, sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  border: 1px solid #000;
  background-color: #000;
  color: #fff;


  padding: .5rem;
  .Loader {
    flex-direction: row-reverse;
    font-weight: 700;
    text-transform: uppercase;
    strong {
      padding: 0;
      margin-right: 1rem;
    }
    .spin {
      width: 1.5rem;
      height: 1.5rem;
      border: 2px solid #ffffff;
      &::after {
        width: 2rem;
        height: 2rem;
        border-width: 3px;
      }
    }
  }
}