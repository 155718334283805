$mobile: 380px;
$tablet: 720px;
$desktop: 980px;
$large: 1400px;

@mixin mobile {
  @media screen and (min-width: $mobile) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: $tablet) {
    @content;
  }
}
@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}
@mixin large {
  @media screen and (min-width: $large) {
    @content;
  }
}
