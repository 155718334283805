@import "../styles/variables";

.Plans {
  > header {
    flex-direction: row;
    padding: 0 1.5rem;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    @include tablet {
      padding: 0 calc((100% - $tablet) / 2 + 1.5rem);
    }
  }
  .typeFilter {
    flex-direction: row;
    padding: 0 1.5rem;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    @include tablet {
      padding: 0 calc((100% - $tablet) / 2 + 1.5rem);
    }
  }
  .PlansList {
    padding: 1rem 1.5rem;
    @include tablet {
      padding: 2rem calc((100% - $tablet) / 2 + 1.5rem);
    }
    > li {
      margin-bottom: 1.5rem;
    }
  }
  .OptionsList {
    display: grid;
    padding: 1rem 1.5rem;
    grid-gap: 1.5rem;
    @include tablet {
      grid-template-columns: repeat(2, 1fr);
      padding: 2rem calc((100% - $tablet) / 2 + 1.5rem);
    }
    &.desktopInlineBy3 {
      @include desktop {
        grid-template-columns: repeat(3, 1fr);
        padding: 2rem calc((100% - $desktop) / 2 - 3rem);
      } 
    }
    > li {
      display: flex;
    }
  }
}
