@import "../styles/variables";

.Contract {
  > header {
    padding: 0 1.5rem;
    @include tablet {
      padding: 0 calc((100% - $tablet) / 2 + 1.5rem);
    }
    h2 {
      font-weight: 600;
      font-size: 1.25rem;
    }
  }
  > section {
    padding: 1.5rem;
    width: 100%;
    height: calc(100vh - 16rem);
    @include tablet {
      margin-bottom: 2rem;
      padding: 2rem calc((100% - $tablet) / 2 + 1.5rem);
    }
    iframe {
      height: 100%;
    }
  }
}

.SignatureModal {
  background-color: #ffffff;
  margin: 2rem 1.5rem;
  padding: 1rem;
  flex-grow: 1;
  @include tablet {
    margin: 3rem calc((100% - $tablet) / 2 + 1.5rem);
    padding: 2rem;
  }
  h3 {
    font-weight: 600;
    font-size: 1.25rem;
  }
  p {
    margin: 1.5rem 0;
  }
  form {
    input[type="text"] {
      position: relative;
      border: 1px solid #b8b8b8;
      background-color: #ffffff;
      cursor: pointer;
      transition: all .3s ease;
      outline: none;
      width: auto;
      line-height: 1.125em;
      font-style: normal;
      font-size: 1rem;
      text-align: left;
      justify-content: center;
      &::placeholder {
        font-weight: 300;
        font-family: Montserrat, sans-serif;
        text-transform: none;
      }
      padding: .75rem .5rem;
      background-color: #f7f7f7;
      @include tablet {
        align-self: flex-start;
      }
    }
    .form-error {
      color: #e63f49;
      padding: .25rem .5rem;
    }
  }
  > footer {
    margin-top: auto;
  }
}
