@import "../styles/variables";

.Confirmation {
  background-color: #ffffff;
  > section {
    flex-grow: 1;
    padding: 2rem 1.5rem;
    justify-content: center;
    align-items: center;
    @include mobile {
      padding: 0 calc((100% - $mobile) / 2 + 1rem);
    }
    h2 {
      font-weight: 600;
      font-size: 1.25rem;
      margin-bottom: 2rem;
    }
    > img {
      width: 8rem;
      margin-bottom: 2rem;
    }
    p {
      text-align: center;
    }
  }
}