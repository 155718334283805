@import "../styles/variables";

.UserForm {
  > header {
    flex-direction: row;
    padding: 0 1.5rem;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    @include tablet {
      padding: 0 calc((100% - $tablet) / 2 + 1.5rem);
    }
  }
  .split {
    display: flex;
    justify-content: center;
    padding: .5rem 1.5rem;
    width: 100%;
    > article {
      max-width:400px;
      margin:auto;
    }
  }
  .withOffer {
    @include desktop {
      flex-direction: row-reverse;
      > article {
        max-width:350px;
        margin-left:1rem;
      }
      > form {
        margin-right: 1rem;
      }
    }
  }
  form {
    padding: .5rem 1.5rem;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    @include tablet {
      width: calc($tablet - 3rem);
      margin: 0rem auto;
    }
    > fieldset {
      width: 100%;
      flex-shrink: 0;
      & + fieldset {
        margin-left: 3rem;
      }
    }
    .labelSpe {
      margin-bottom: 1rem;
      &:first-of-type {
        align-self: flex-start;
      }
      em {
        margin-top: .25rem;
        font-weight: 400;
        opacity: .6;
        font-size: .875rem;
        font-style: italic;
      }
      input[type="text"], input[type="tel"], input[type="email"] {
        position: relative;
        border: 1px solid #b8b8b8;
        background-color: #ffffff;
        cursor: pointer;
        transition: all .3s ease;
        outline: none;
        width: auto;
        line-height: 1.125em;
        font-style: normal;
        font-size: 1rem;
        text-align: left;
        justify-content: center;
        &::placeholder {
          font-weight: 300;
          font-family: Montserrat, sans-serif;
          text-transform: none;
        }
        padding: .75rem .5rem;
        &.error {
          border-color: #e63f49;
        }
      }
      input[type="date"] {
        position: relative;
        border: 1px solid #b8b8b8;
        background-color: #ffffff;
        cursor: pointer;
        transition: all .3s ease;
        outline: none;
        width: auto;
        line-height: 1.125em;
        font-style: normal;
        font-size: 1rem;
        text-align: left;
        justify-content: center;
        &::placeholder {
          font-weight: 300;
          font-family: Montserrat, sans-serif;
          text-transform: none;
        }
        padding: .75rem .5rem;
        flex-direction: row;
        &.error {
          border-color: #e63f49;
        }
      }
    }
    select:not(.PhoneInputCountrySelect) {
      position: relative;
      border: 1px solid #b8b8b8;
      background-color: #ffffff;
      cursor: pointer;
      transition: all .3s ease;
      outline: none;
      width: auto;
      line-height: 1.125em;
      font-style: normal;
      font-size: 1rem;
      text-align: left;
      justify-content: center;
      &::placeholder {
        font-weight: 300;
        font-family: Montserrat, sans-serif;
        text-transform: none;
      }
      padding: .75rem 2rem .75rem .5rem;
      background-image: url('../../public/icons/up-down-chevron.svg');
      background-repeat: no-repeat;
      background-position: right .5rem center;
      background-size: .8rem;
      &:invalid {
        color: #e63f49;
      }
      &.error {
        border-color: #e63f49;
      }
    }
  }

  .ClubPreview {
    margin-top: auto;
    padding: 1.5rem 1.5rem 0;
    img {
      border-radius: .5rem;
    }
    @include tablet {
      padding: 2rem calc((100% - $tablet) / 2 + 1.5rem) 0;
    }
  }
  .error {
    input[type='checkbox'] {
      border-color: #e63f49;
    }
    span {
      color: #e63f49;
    }
  }
}

.pac-target-input {
  position: relative;
  border: 1px solid #b8b8b8;
  background-color: #ffffff;
  cursor: pointer;
  transition: all .3s ease;
  outline: none;
  width: auto;
  line-height: 1.125em;
  font-style: normal;
  font-size: 1rem;
  text-align: left;
  justify-content: center;
  &::placeholder {
    font-weight: 300;
    font-family: Montserrat, sans-serif;
    text-transform: none;
  }
  padding: .75rem .5rem;
  &.error {
    border-color: #e63f49;
  } 
}