@import "./styles/variables";

.OptionPreview {
  cursor:pointer;
  flex-grow: 1;
  border-radius: .5rem;
  overflow: hidden;
  > header {
    padding: 1.5rem;
    h3 {
      text-align:center;
      font-size:1.2rem;
      font-weight: 500;
      // text-transform: uppercase;
      margin-bottom: .5rem;
      font-family: "Paytone One", sans-serif;
    }
    strong {
      font-size: 1.1rem;
      font-weight: 600;
      margin-right: 1rem;
    }
    span {
      flex-direction: row;
      align-items: flex-end;
      em {
        margin-bottom: .5rem;
        font-size: 1.3rem;
        font-weight: 600;
        margin-right: 1rem;
      }
    }
  }
  input {
    margin-right: .5rem;
    margin-top: .15rem;
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
    border: 1px solid #000;
    &:checked {
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        border: 2px solid white;
        box-sizing: border-box;
      }
    }
  }
  > section {
    padding: 1.5rem 1rem;
    padding-top: 0;
    flex-grow:1;
    img {
      max-width: 200px;
      align-self: center;
    }
  }
  > footer {
    justify-content: center;
    cursor: pointer;
    label {
      display:flex;
      flex-grow:1;
      padding: 1rem;
      align-items: flex-start;
      margin-bottom: .5rem;
      flex-direction: row;
      justify-content: center;
      span {
        font-weight: 500;
        line-height: 1.2rem;
      }
    }
  }
}
