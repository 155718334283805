.phoneWrapper {
}
.phoneWrapper .PhoneInput{
	flex-direction: row;
}

.PhoneInput input {
	position: relative;
	border: 1px solid #b8b8b8;
	background-color: #ffffff;
	cursor: pointer;
	transition: all .3s ease;
	outline: none;
	width: auto;
	line-height: 1.125em;
	font-style: normal;
	font-size: 1rem;
	text-align: left;
	justify-content: center;
	&::placeholder {
	  font-weight: 300;
	  font-family: Montserrat, sans-serif;
	  text-transform: none;
	}
	padding: .75rem .5rem;
	&.error {
	  border-color: #e63f49;
	}
}

.PhoneInputCountryIcon {
	margin: auto;
}