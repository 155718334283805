@import "../styles/variables";

.Sepa {
  > header {
    flex-direction: row;
    padding: 0 1.5rem;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    @include tablet {
      padding: 0 calc((100% - $tablet) / 2 + 1.5rem);
    }
  }
  > form {
    padding: .5rem 1.5rem;
    @include tablet {
      padding: 1rem calc((100% - $tablet) / 2 + 1.5rem);
    }
    label {
      margin-bottom: 1rem;
    }
    input[type="text"] {
      position: relative;
      border: 1px solid #b8b8b8;
      background-color: #ffffff;
      cursor: pointer;
      transition: all .3s ease;
      outline: none;
      width: auto;
      line-height: 1.125em;
      font-style: normal;
      font-size: 1rem;
      text-align: left;
      justify-content: center;
      &::placeholder {
        font-weight: 300;
        font-family: Montserrat, sans-serif;
        text-transform: none;
      }
      padding: .75rem .5rem;
    }
  }
}
