@import "./styles/variables";

.CartSummary {
  padding: 1.5rem;
  width: 100%;
  @include tablet {
    margin-bottom: 2rem;
    padding: 2rem calc((100% - $tablet) / 2 + 1.5rem);
  }
  > section {
    margin-bottom: 1rem;
    > h4 {
      font-weight: 800;
      margin-bottom: .5rem;
      font-size: 1.15rem;
    }
    > ul {
      li {
        margin-bottom: .5rem;
      }
    }
    article {
      header {
        flex-direction: row;
        h5 {
          margin-right: 1.5rem;
          font-weight: 600;
        }
        strong {
          margin-left: auto;
          white-space: nowrap;
          font-weight: 800;
          align-items: flex-end;
          span {
            font-weight: 400;
            font-size: .875rem;
          }
        }
      }
      > em {
        margin-top: .5rem;
        flex-direction: row;
        justify-content: space-between;
        span {

        }
        strong {
          font-weight: 700;
        }
      }
      > ul {
        padding: .5rem 0;
        li {
          margin-bottom: .25rem;
          opacity: .6;
        }
      }
    }
    > div {
      flex-direction: row;
      > span {
        margin-left: auto;
        margin-right: 1.5rem;
        font-weight: 600;
      }
      strong {
        white-space: nowrap;
        font-weight: 800;
        align-items: flex-end;
        span {
          font-weight: 400;
          font-size: .875rem;
        }
      }
    }
  }
}